import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import parse from "html-react-parser";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Navigation from "../components/navigation";
import SafeHtmlParser from "../components/safe-html-parser";
import Wave from "../images/wave.svg";
import { BgImage } from "gbimage-bridge";
import { Helmet } from "react-helmet";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

const PolicyTemplate = ({ data }) => {
	const { wpPolicy } = data;
	const { siteUrl } = data.site.siteMetadata;

	return (
		<Layout>
			<GatsbySeo
				title={wpPolicy.title}
				description={wpPolicy.title}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/${wpPolicy.slug}`,
					title: `${wpPolicy.title}`,
					description: `${wpPolicy.title}`,
				}}
			/>
			<section>
				<BgImage
					className=" position-relative"
					image={getImage(
						wpPolicy.policyFields.image.localFile.childImageSharp
							.gatsbyImageData
					)}
				>
					<div
						style={{ opacity: 0.4 }}
						className="w-100 h-100 bg-black position-absolute start-0 top-0"
					></div>
					<Container
						style={{ zIndex: "2" }}
						className="h-100 position-relative py-8 py-lg-10"
					>
						<Row className="align-items-center justify-content-center text-center h-100">
							<Col xs={12} lg={8}>
								<h1
									style={{ lineHeight: "90%" }}
									className=" display-1 Alex-Brush   text-white"
								>
									{wpPolicy.title}
								</h1>
							</Col>
						</Row>
					</Container>
					<img
						src={Wave}
						alt=""
						style={{
							width: "100%",
							bottom: "-4px",
						}}
						className="position-absolute  start-0"
					/>
				</BgImage>
			</section>
			<section>
				<Container className="my-6">
					<Row>
						<Col>
							<div className="policy blog-content px-3 px-lg-0">
								<SafeHtmlParser htmlContent={wpPolicy.content} />
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default PolicyTemplate;

export const blogData = graphql`
	query ($id: String!) {
		wpPolicy(id: { eq: $id }) {
			title
			content
			uri
			policyFields {
				image {
					altText
					localFile {
						childImageSharp {
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
